<template>
    <div class="flex">
        <v-select
            v-show="showLocation"
            :dense="dense"
            :single-line="singleLine"
            :hide-details="hideDetails"
            :outlined="outlined"
            :clearable="clearable"
            v-model="locationId"
            label="State"
            class="w-150 mr-2"
            :items="sortItems(computedLocationItems)"
            @change="locationChangeHandler"
        ></v-select>
        <v-autocomplete
            :dense="dense"
            :single-line="singleLine"
            :hide-details="hideDetails"
            :outlined="outlined"
            :clearable="clearable"
            v-model="locationProgramId"
            label="Program"
            class="w-200"
            :items="sortItems(filteredLocationProgramItems)"
            @change="locationProgramChangeHandler"
        ></v-autocomplete>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import sortItems from '@/components/shared/mixins/sortItems'

export default {
    name: 'StateProgramFilter',
    mixins: [sortItems],
    props: {
        dense: {
            type: Boolean,
            default: false
        },
        singleLine: {
            type: Boolean,
            default: false
        },
        hideDetails: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: false
        },
        clearable: {
            type: Boolean,
            default: false
        },
        userScoped: {
            type: Boolean,
            default: true
        },
        showLocation: {
            type: Boolean,
            default: true
        },
        showOnlyStuni: {
            type: Boolean,
            default: false
        },
        value: {
            type: Object
        }
    },
    data() {
        return {
            locationId: null,
            locationProgramId: null
        }
    },
    methods: {
        locationChangeHandler() {
            this.locationProgramId = null
            this.locationProgramChangeHandler()
        },
        locationProgramChangeHandler() {
            const locationProgram = {
                locationId: this.locationId,
                locationProgramId: this.locationProgramId
            }
            this.$emit('input', locationProgram)
            this.$emit('change', locationProgram)
        }
    },
    computed: {
        ...mapState({
            loggedInUser: (state) => state.app.loggedInUser,
            locationItems: (state) => state.app.locationItems,
            siteSections: (state) => state.app.siteSections
        }),
        computedLocationItems() {
            if (this.userScoped) return this.loggedInUser.locationItems

            return this.locationItems
        },
        computedLocationProgramItems() {
            if (this.userScoped) return this.loggedInUser.locationProgramItems

            return this.siteSections
        },
        filteredLocationProgramItems() {
            let locationPrograms = []
            if (!this.locationId) {
                locationPrograms = this.computedLocationProgramItems
            } else {
                locationPrograms = this.computedLocationProgramItems.filter(
                    ({locationId}) => locationId === this.locationId
                )
            }
            if (this.showOnlyStuni) {
                locationPrograms = locationPrograms.filter(
                    (l) => l.text.toLowerCase().indexOf('street') > -1
                )
            }
            return locationPrograms
        }
    },
    mounted() {
        if (this.showOnlyStuni && !this.value.locationProgramId) {
            this.locationProgramId = this.filteredLocationProgramItems?.at(0)?.value
            this.locationProgramChangeHandler()
        }
    },
    watch: {
        value: {
            immediate: true,
            deep: true,
            handler() {
                if (this.value) {
                    this.locationId = this.value.locationId
                    this.locationProgramId = this.value.locationProgramId
                    if (this.showOnlyStuni && !this.value.locationProgramId) {
                        this.locationProgramId = this.filteredLocationProgramItems?.at(0)?.value
                        this.locationProgramChangeHandler()
                    }
                } else {
                    this.locationId = this.computedLocationItems?.[0] || null
                    this.locationProgramId = null
                }
            }
        }
    }
}
</script>
