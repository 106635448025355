<template>
    <div class="container flex flex-col">
        <div class="container flex flex-col p-0">
            <div class="bg-white-pure list-wrapper">
                <div class="flex pb-8 items-center w-full justify-between">
                    <div class="flex items-center">
                        <IconSvg class="text-primary-light w-10 mr-2" />
                        <h1 class="text-black mr-2 text-xl lg:text-2xl" data-cy="adminTitle">
                            TSU App Management
                        </h1>
                    </div>
                </div>
                <div class="flex items-center mb-6 search">
                    <v-text-field
                        solo
                        flat
                        hide-details
                        outlined
                        single-line
                        dense
                        label="Search"
                        prepend-inner-icon="search"
                        v-model="filter.search"
                        @input="filterChangeHandler"                        
                    ></v-text-field>
                </div>

                <div class="flex items-center mb-6">
                    <fa-icon icon="filter" class="tablet-hide text-sm text-black mr-4" />
                    <div class="w-150 mr-2">
                        <v-select
                            dense
                            single-line
                            hide-details
                            outlined
                            clearable
                            label="Status"
                            v-model="filter.status"
                            :items="stuniStatuses"
                            @change="filterChangeHandler"
                        ></v-select>
                    </div>
                    <div class="w-130 mr-2">
                        <v-select
                            dense
                            single-line
                            hide-details
                            outlined
                            label="Role"
                            v-model="filter.role"
                            :items="stuniRoles"
                            @change="filterChangeHandler"
                            clearable
                        ></v-select>
                    </div>
                    <div class="mr-2">
                        <LocationProgramFilter
                            dense
                            single-line
                            hide-details
                            outlined
                            clearable
                            :user-scoped="true"
                            v-model="filter.locationProgram"
                            @change="filterChangeHandler"
                        />
                    </div>
                    <v-btn
                        depressed
                        class="mr-4"
                        @click="resetFiltersHandler"
                        data-cy="resetFiltersHandler"
                    >Reset</v-btn>
                </div>
                <div class="a-stuni-list">
                    <div class="flex flex-row">
                        <div class="flex items-center w-full">
                            <StuniTable
                                ref="userTable"
                                :table-data="users"
                                :offset="pagination.offset"
                                :limit="pagination.limit"
                                :sort="sort"
                                :total="total"
                                :loading="loading"
                                @update-pagination="updatePagination"
                                @perform-action="performAction"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BackLink from '@/components/shared/BackLink'
import GET_STUDENTS from '@/graphql/queries/getStudentsForAdmin.gql'
import UPDATE_USER_STATUS from '@/graphql/mutations/updateUserStatus.gql'
import DEACTIVATE_USER from '@/graphql/mutations/deactivateUser.gql'
import REACTIVATE_USER from '@/graphql/mutations/reactivateUser.gql'
import APPROVE_STUNI from '@/graphql/mutations/approveStuni.gql'
import APPROVE_GUEST_STUNI from '@/graphql/mutations/approveGuestStuni.gql'
import REJECT_STUNI from '@/graphql/mutations/rejectStuni.gql'
import REINVITE_USER from '@/graphql/mutations/reinviteUser.gql'
import GET_ALL_CLIENTS from '@/graphql/queries/getAllClients.gql'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import {mapState} from 'vuex'
import StuniTable from '@/components/stuni-management/partials/StuniTable'
import IconSvg from '@/assets/icons/nav/icons_cog.svg'
import LocationProgramFilter from '@/components/shared/LocationProgramFilter'
import {StuniHelper, StuniStatus, StuniAction} from '@/components/shared/mixins/stuniMixin'
import {UserHelper} from '@/components/shared/mixins/userMixin'
import MessageDialog from '@/components/shared/mixins/messageDialog'

const DEFAULT_PAGINATION = {
    offset: 0,
    limit: 10
}
const DEFAULT_FILTER = {
    search: null,
    locationProgram: {
        locationId: null,
        locationProgramId: null
    },
    status: null,
    role: null
}
const DEFAULT_SORT = ['firstName']

export default {
    name: 'StuniList',
    components: {BackLink, IconSvg, StuniTable, LocationProgramFilter},
    mixins: [StuniHelper, UserHelper, MessageDialog],
    data() {
        return {
            loading: false,
            users: [],
            total: 0,
            pagination: {...DEFAULT_PAGINATION},
            sort: [...DEFAULT_SORT],
            filter: {
                ...DEFAULT_FILTER,
                locationProgram: {...DEFAULT_FILTER.locationProgram}
            },
            clients: []
        }
    },
    apollo: {
        users: {
            debounce: 200,
            query: GET_STUDENTS,
            variables() {
                return {
                    ...this.pagination,
                    filter: this.queryFilter,
                    orderBy: snakecaseKeys(this.orderBy, {deep: true})
                }
            },
            result({data: {users, total}}) {
                this.total = total.aggregate.count
                users = camelcaseKeys(users || [], {deep: true})
                users.forEach(user => {
                    const locationNames = []
                    const locationProgramNames = []
                    const locationProgramIds = []
                    if (user.status !== 'PENDING_APPROVAL') {
                        if (user.client?.episodes?.length) {
                            user.client?.episodes.forEach(
                                ({locationProgram: {locationProgramId}}) => {
                                    locationProgramIds.push(locationProgramId)
                                }
                            )
                        }
                    } else {
                        if (user.campusId) locationProgramIds.push(user.campusId)
                    }
                    if (locationProgramIds.length) {
                        locationProgramIds.forEach(locationProgramId => {
                            const locationProgram = this.loggedInUserLocationPrograms.find(
                                locationProgram => locationProgram.locationProgramId === locationProgramId
                            )
                            if (!locationProgram) return

                            const locationProgramName = locationProgram.text
                            if (!locationProgramNames.includes(locationProgramName))
                                locationProgramNames.push(locationProgramName)

                            const locationName = locationProgramName.split(' - ')[0]
                            if (!locationNames.includes(locationName))
                                locationNames.push(locationName)
                        })
                    }

                    user.locationNames = locationNames.sort()
                    user.locationProgramNames = locationProgramNames.sort()

                    user.profile = user.client ?? user.initialProfile
                    user.clientId = user.client?.clientId
                    user.openEpisodeCount =
                        user.client?.openEpisodeCountAggregate.aggregate.count ?? 0
                })

                this.users = users
            },
            watchLoading(isLoading, countModifier) {
                this.loading = isLoading
            }
        },
        clients: {
            query: GET_ALL_CLIENTS,
            variables() {
                return {}
            },
            result({data: {clients}}) {
                clients.forEach(client => {
                    client.name = `${client.first_name} ${client.last_name}`
                })
                this.clients = camelcaseKeys(clients || [], {deep: true})
            },
            watchLoading(isLoading) {
                this.loading = isLoading
            }
        }
    },
    beforeMount() {
        this.loadFilter(this.$route.query)
    },
    mounted() {
        this.$root.$on('change-user-status', this.changeStuniStatus)
    },
    methods: {
        loadFilter(query) {
            this.filter.search = query.search || DEFAULT_FILTER.search
            this.filter.locationProgram = {
                locationId:
                    (!isNaN(query.locationId) && parseInt(query.locationId)) ||
                    DEFAULT_FILTER.locationProgram.locationId,
                locationProgramId:
                    (!isNaN(query.locationProgramId) && parseInt(query.locationProgramId)) ||
                    DEFAULT_FILTER.locationProgram.locationProgramId
            }
            this.filter.status = query.status || DEFAULT_FILTER.status
            this.filter.role = query.role || DEFAULT_FILTER.role

            this.pagination.limit =
                (!isNaN(query.limit) && parseInt(query.limit)) || DEFAULT_PAGINATION.limit
            this.pagination.offset =
                (!isNaN(query.offset) && parseInt(query.offset)) || DEFAULT_PAGINATION.offset

            this.sort = (query.sort && (Array.isArray(query.sort) ? query.sort : [query.sort])) || [...DEFAULT_SORT]
        },
        updateRoute() {
            const query = {
                ...this.filter,
                locationId: this.filter.locationProgram?.locationId,
                locationProgramId: this.filter.locationProgram?.locationProgramId,
                ...this.pagination,
                sort: this.sort.join(',')
            }

            delete query.locationProgram

            this.$router.replace({query}).catch(()=>{});
        },
        filterChangeHandler() {
            this.resetPage()
            this.updateRoute()
        },
        updatePagination({page, itemsPerPage, sortBy, sortDesc}) {
            if (itemsPerPage * (page - 1) > this.total) return

            this.pagination.limit = itemsPerPage
            this.pagination.offset = this.pagination.limit * (page - 1)
            
            this.sort = sortBy.map((fieldName, i) => `${fieldName}${sortDesc[i] ? ' desc' : ''}`)

            this.updateRoute()
        },
        resetPage() {
            this.pagination.offset = 0
        },
        resetFiltersHandler() {
            this.filter.search = null
            this.filter.status = null
            this.filter.role = null

            this.filter.locationProgram = {
                locationId: null,
                locationProgramId: null
            }

            this.filterChangeHandler()
        },
        performAction({user, action}) {
            switch (action) {
                case StuniAction.REACTIVATE:
                    this.activateUser(user, StuniAction.REACTIVATE)
                    break
                case StuniAction.DEACTIVATE:
                    this.deactivateUser(user, StuniAction.DEACTIVATE)
                    break
                case StuniAction.APPROVE_AS_CLIENT:
                    this.approveAsClientStuni(
                        {user, clients: this.clients},
                        StuniAction.APPROVE_AS_CLIENT
                    )
                    break
                case StuniAction.APPROVE_AS_GUEST:
                    this.approveAsGuestStuni(user, StuniAction.APPROVE_AS_GUEST)
                    break
                case StuniAction.REJECT:
                    this.rejectStuni(user, StuniAction.REJECT)
                    break
                case StuniAction.MARK_AS_PENDING_APPROVAL:
                    this.markAsPendingApprovalStuni(user, StuniAction.MARK_AS_PENDING_APPROVAL)
                    break
                case StuniAction.REINVITE:
                    this.reinviteUser(user, StuniAction.REINVITE)
                    break
                default:
                    console.log(action)
            }
        },
        changeStuniStatus({user, clientId, action}) {
            console.log(user, action)
            let variables = {}
            let mutation = null

            if (action === StuniAction.APPROVE_AS_CLIENT) {
                variables = {
                    userId: user.user.userId,
                    clientId
                }
            } else {
                variables = {userId: user.userId}
            }

            switch (action) {
                case StuniAction.DEACTIVATE:
                    mutation = DEACTIVATE_USER
                    break
                case StuniAction.REACTIVATE:
                    mutation = REACTIVATE_USER
                    break
                case StuniAction.APPROVE_AS_CLIENT:
                    mutation = APPROVE_STUNI
                    break
                case StuniAction.APPROVE_AS_GUEST:
                    mutation = APPROVE_GUEST_STUNI
                    break
                case StuniAction.REJECT:
                    mutation = REJECT_STUNI
                    break
                case StuniAction.MARK_AS_PENDING_APPROVAL:
                    mutation = UPDATE_USER_STATUS
                    break
                case StuniAction.REINVITE:
                    mutation = REINVITE_USER
                    break
                default:
                    mutation = null
                    console.log(action)
            }

            if (mutation) {
                if (mutation === UPDATE_USER_STATUS) {
                    variables.status = StuniStatus.PENDING_APPROVAL
                }

                this.$apollo
                    .mutate({
                        mutation,
                        variables
                    })
                    .then(async res => {
                        await this.$apollo.provider.defaultClient.resetStore()

                        this.message = 'The student status has been updated.'
                        this.type = 'success'
                        this.closeModal()
                    })
                    .catch(err => {
                        let body = err?.graphQLErrors[0]?.extensions?.internal?.response?.body
                        let message = typeof body === 'string' ? body : body?.message

                        if (!message) message = 'Error inviting the new staff'
                        this.message = message
                        this.type = 'error'
                        this.$root.$emit('modal-loading', false)
                    })
                    .finally(() => {
                        this.showMessage({duration: 5000})
                    })
            }
        }
    },
    computed: {
        ...mapState({
            staticChoices: state => state.app.staticChoices,
            loggedInUser: state => state.app.loggedInUser
        }),
        locationProgramIdsByLocationId() {
            if (!this.filter.locationProgram.locationId) {
                return this.loggedInUserLocationPrograms.map(({locationProgramId}) => locationProgramId)
            }
            if (!this.loggedInUserLocationPrograms?.length) return []

            return this.loggedInUserLocationPrograms
                .filter(({locationId}) => locationId === this.filter.locationProgram.locationId)
                .map(({locationProgramId}) => locationProgramId)
        },
        // TODO: refactor this as it seems strange to have to do the search filter stuff here
        // when you can specify something like `{first_name: {}}` to ignore the filter
        queryFilter() {
            const queryFilter = [
                {status: this.filter.status ? {_eq: this.filter.status} : {}},
                {role: this.filter.role ? {_eq: this.filter.role} : {}},
                {account_type: {_eq: 'student'}}
            ]

            if (this.filter.search) {
                const searchComparator = {_ilike: `%${this.filter.search}%`}
                const searchFilter = [
                    {first_name: searchComparator},
                    {last_name: searchComparator},
                    {phone: searchComparator},
                    {email: searchComparator},
                    {client: {phone: searchComparator}},
                    {client: {email: searchComparator}}
                ]
                const id = isNaN(this.filter.search) ? null : Number(this.filter.search)
                if (id) {
                    searchFilter.push({
                        client: {client_id: {_eq: id}}
                    })
                }
                queryFilter.push({
                    _or: searchFilter
                })
            }

            let locationProgramIds = []
            if (!this.filter.locationProgram.locationId) {
                locationProgramIds = this.locationProgramIdsByLocationId
            } else {
                locationProgramIds = this.filter.locationProgram.locationProgramId
                    ? [this.filter.locationProgram.locationProgramId]
                    : this.locationProgramIdsByLocationId
            }
            
            queryFilter.push({
                _or: [{
                        _and: [
                            {
                                _or: [
                                    { status: { _eq: 'PENDING_APPROVAL' } },
                                    { status: { _eq: 'REJECTED' } },
                                    { role: { _eq: "guest" } }
                                ]
                            },
                            { campus_id: { _in: locationProgramIds } }
                        ]
                    }, {
                        _and: [
                            { status: { _neq: 'PENDING_APPROVAL' } },
                            {
                                client: {
                                    episodes: {
                                        location_program: {
                                            location_program_id: {
                                                _in: locationProgramIds
                                            }
                                        }
                                    }
                                }
                            }
                        ]
                    }
                ]
            })

            return {
                _and: queryFilter
            }
        },
        orderBy() {
            let orderBy = {}
            if (this.sort?.length) {
                this.sort.forEach((sortOne) => {
                    const [fieldName, sortDir] = sortOne.split(' ')
                    orderBy[fieldName] = sortDir === 'desc' ? 'desc_nulls_last' : 'asc_nulls_first'
                })
            }
            if (!orderBy.userId) orderBy.userId = 'asc_nulls_first'

            return orderBy
        },
        loggedInUserLocationPrograms() {
            return this.loggedInUser.locationPrograms.map(({ locationProgramId, location, program }) => ({
                locationProgramId,
                text: `${location.name} - ${program.name}`,
                locationId: location.locationId,
                programId: program.programId
            }))
        },
        goBackText() {
            return `Back to Stuni Management`
        }
    },
    destroyed() {
        this.$root.$off('change-user-status')
    }
}
</script>
