var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-staff__table flex flex-col w-full"},[_c('v-data-table',{ref:"stuniTable",staticClass:"a-table w-full capitalize",attrs:{"id":"stuni-table","single-select":"","item-key":"userId","headers":_vm.headers,"items":_vm.tableData,"footer-props":_vm.footerProps,"server-items-length":_vm.total,"data-cy":"stuniList","loading":_vm.loading,"options":_vm.options},on:{"click:row":_vm.rowClickHandler,"update:options":_vm.updatePagination},scopedSlots:_vm._u([{key:"item.firstName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"w-100 truncate",attrs:{"title":item.firstName}},[_vm._v(_vm._s(item.firstName))])]}},{key:"item.lastName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"w-100 truncate",attrs:{"title":item.lastName}},[_vm._v(_vm._s(item.lastName))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"uppercase font-semibold text-xs",class:_vm.getStatusClass(item.status)},[_vm._v(" "+_vm._s(_vm.getStatusTitle(item.status))+" ")])]}},{key:"item.locations",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"w-200 truncate"},[_vm._v(_vm._s(_vm.getLocations(item)))])]}},{key:"item.locationPrograms",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"w-200 truncate",attrs:{"title":_vm.getLocationPrograms(item)}},[_vm._v(" "+_vm._s(_vm.getLocationPrograms(item))+" ")])]}},{key:"item.editRecord",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right flex justify-end"},[_c('a',{staticClass:"hover:text-primary mx-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.rowClickHandler(item)}}},[_c('fa-icon',{staticClass:"text-grey-2 ml-3",attrs:{"icon":"chevron-right"}})],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }