<template>
    <div class="c-staff__table flex flex-col w-full">
        <v-data-table
            id="stuni-table"
            ref="stuniTable"
            single-select
            item-key="userId"
            :headers="headers"
            :items="tableData"
            :footer-props="footerProps"
            class="a-table w-full capitalize"
            :server-items-length="total"
            data-cy="stuniList"
            :loading="loading"
            :options="options"
            @click:row="rowClickHandler"
            @update:options="updatePagination"
        >
            <template class="" v-slot:item.firstName="{item}">
                <div class="w-100 truncate" :title="item.firstName">{{ item.firstName }}</div>
            </template>
            <template class="" v-slot:item.lastName="{item}">
                <div class="w-100 truncate" :title="item.lastName">{{ item.lastName }}</div>
            </template>
            <template class="" v-slot:item.status="{item}">
                <div class="uppercase font-semibold text-xs" :class="getStatusClass(item.status)">
                    {{ getStatusTitle(item.status) }}
                </div>
            </template>
            <template class="" v-slot:item.locations="{item}">
                <div class="w-200 truncate">{{ getLocations(item) }}</div>
            </template>
            <template class="" v-slot:item.locationPrograms="{item}">
                <div class="w-200 truncate" :title="getLocationPrograms(item)">
                    {{ getLocationPrograms(item) }}
                </div>
            </template>
            <template class="" v-slot:item.editRecord="{item}">
                <div class="text-right flex justify-end">
                    <a
                        href="#"
                        class="hover:text-primary mx-2"
                        @click.prevent="rowClickHandler(item)"
                    >
                        <fa-icon icon="chevron-right" class="text-grey-2 ml-3" />
                    </a>
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import {StuniHelper} from '@/components/shared/mixins/stuniMixin'

export default {
    name: 'StuniTable',
    mixins: [StuniHelper],
    props: {
        tableData: {
            type: Array,
            default: () => []
        },
        total: {
            type: Number,
            default: 5
        },
        offset: {
            type: Number,
            default: 0
        },
        limit: {
            type: Number,
            default: 10
        },
        sort: {
            type: Array,
            default: () => []
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            footerProps: {
                'items-per-page-options': [10, 20, 30, 40]
            },
            headers: [
                {text: 'Status', align: 'left', value: 'status', class: 'user-status--header'},
                {
                    text: 'First Name',
                    align: 'left',
                    value: 'firstName',
                    class: 'first-name--header w-200'
                },
                {
                    text: 'Last Name',
                    align: 'left',
                    value: 'lastName',
                    class: 'last-name--header w-200'
                },
                {text: 'State(s)', align: 'left', value: 'locations', class: ''},
                {text: 'Program(s)', align: 'left', value: 'locationPrograms', class: ''},
                {text: '', align: 'left', value: 'editRecord', fixed: true}
            ],
            loaded: false
        }
    },
    mounted() {
        this.loaded = true
    },
    methods: {
        updatePagination(options) {
            if (!this.loaded) return

            this.$emit('update-pagination', options)
        },
        getStuniClientRoute(item) {
            return {name: 'client-overview', params: {clientId: item.clientId}}
        },
        rowClickHandler(item) {
            this.$router.push({name: 'stuni-details', params: {userId: item.userId}})
        },
        getLocations(item) {
            return item.locationNames.join(', ')
        },
        getLocationPrograms(item) {
            return item.locationProgramNames.join(', ')
        }
    },
    computed: {
        ...mapState({
            staticChoices: state => state.app.staticChoices
        }),
        options() {
            const sortBy = []
            const sortDesc = []
            if (this.sort?.length) {
                this.sort.forEach((sortOne) => {
                    const [fieldName, sortDir] = sortOne.split(' ')
                    sortBy.push(fieldName)
                    sortDesc.push(sortDir === 'desc')
                })
            }
            return {
                page: this.offset / this.limit + 1,
                itemsPerPage: this.limit,
                sortBy,
                sortDesc
            }
        }
    }
}
</script>
